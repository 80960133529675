import { mapState, mapGetters, mapActions } from 'vuex'
import store from '@state/store'

export const authComputed = {
  ...mapState('auth', {
    currentUser: (state) => state.currentUser,
  }),
  ...mapGetters('auth', [
    'loggedIn',
    'idStepRegistration',
    'userIsSuspended',
    'userIsCanceled',
    'userRegistration',
  ]),
}

export const authMethods = {
  ...mapActions('auth', [
    'logIn',
    'logOut',
    'updateUser',
    'changePassword',
    'createUser',
    'setFirstLogin',
    'passwordReset',
    'passwordRequestResent',
  ]),
  checkStepRegistration: (step) => {
    return !!store.state.auth.currentUser
      ? store.state.auth.currentUser.idStepRegistration === step
      : false
  },
}

export const paymentMethods = {
  ...mapActions('paymentInfo', [
    'saveCompanyPaymentInfoField',
    'getPaymentInfoByRegistering',
    'getPaymentStep',
    'createPayment',
    'applyDiscount',
    'setCompanyPaymentsInfoLoadingCep',
    'getPaymentData',
    'selectPlan',
    'getFirstPaymentSummary',
  ]),
}
export const paymentComputed = {
  // ...mapState('paymentInfo', {

  // }),
  ...mapGetters('paymentInfo', [
    'companyPaymentInfo',
    'companyPaymentPedingPayment',
    'companyPaymentShowInfo',
    'companyPaymentShowBillDetails',
    'companyPaymentPedingPaymentCreditcard',
    'companyPaymentPedingPaymentBoleto',
    'companyPaymentPedingPaymenCreditCard',
    'companyPaymentFailBoleto',
    'companyPaymentFailCreditcard',
    'companyPaymentInfoLoadingCep',
    'companyPaymentInfoLoadingCreate',
    'companyPaymentInfoLoadingPlan',
    'companyPaymentInfoLoadingCreateWithBoleto',
    'companyPaymentInfoLoadingCreateWithCreditCard',
    'companyPaymentInfoCepNotFound',
    'totalPlan',
    'stepPaymentInfo',
    'stepPaymentResume',
    'paymentErrorMessage',
    'paymentError',
    'paymentListData',
    'checkIfPlanIsFree',
    'firstPaymentSummary',
  ]),
}
export const companyMethods = {
  ...mapActions('company', [
    'getCompany',
    'getContracts',
    'getPartners',
    'getPartner',
    'updateTel',
    'getAnnualDeclaration',
    'updateCompany',
    'getCurrentCompany',
    'setLaodingCep',
  ]),
}
export const companyComputed = {
  ...mapGetters('company', [
    'company',
    'alerts',
    'laodingCep',
    'loadingContratoSocial',
    'loadingIptu',
    'companyIncomplete',
  ]),
}

export const partnerMethods = {
  ...mapActions('partner', [
    'getAllPartners',
    'deletePartner',
    'savePartnerField',
    'getPartnerAccountable',
    'createPartner',
    'fetchPartner',
    'setPartnerLaodingCep',
  ]),
}
export const partnerComputed = {
  // ...mapState('partner', []),
  ...mapGetters('partner', [
    'partner',
    'partners',
    'partnerUser',
    'partnerAccountable',
    'partnerLoadingRg',
  ]),
}

export const formCompanyPartnerMethods = {
  ...mapActions('formCompanyPartner', [
    'getValidations',
    'forceValidation',
    'checkIfPartnerIsValidById',
  ]),
}

export const formCompanyPartnerComputed = {
  ...mapGetters('formCompanyPartner', [
    'tabItems',
    'forceValidationCompany',
    'forceValidationPartners',
    'allPartnersIsValid',
    'companyIsValid',
  ]),
}

export const cepMethods = {
  ...mapActions('cep', ['search']),
}
export const cepComputed = {
  ...mapState('cep', {
    cepMask: (state) => state.mask,
    cepRules: (state) => state.rules,
    cepAddress: (state) => state.address,
  }),
}
export const stateMethods = {
  ...mapActions('state', ['getAllStates']),
}
export const stateComputed = {
  ...mapState('state', {
    stateLoading: (state) => state.laoding,
    states: (state) => state.states,
  }),
}
export const cityMethods = {
  ...mapActions('city', [
    'getCities',
    'getAllCities',
    'serchCityByStateAndName',
    'getCity',
    'getCityByStateName',
  ]),
}
export const cityComputed = {
  ...mapState('city', {
    cityLoading: (state) => state.laoding,
    city: (state) => state.city,
    cities: (state) => state.cities,
  }),
}

export const fileMethods = {
  urlToDonload: (id) => {
    return !!store.state.auth.token
      ? `${process.env.VUE_APP_API_URI}/file/download-jwt/${id}?jwt=${store.state.auth.token.accessToken}`
      : ''
  },
  fileToBase64: async (file) => {
    return new Promise((resolve) => {
      if (typeof file !== 'object' && file.constructor.name !== 'File')
        return resolve(null)
      const reader = new FileReader()
      reader.onloadend = (e) => {
        const fileData = e.target.result
        const file64 = fileData.replace(/^data:.+;base64,/, '')
        return resolve(file64)
      }
      reader.readAsDataURL(file)
    })
  },
}
