<template>
  <v-dialog v-model="valueLocal" width="800px">
    <v-card>
      <v-container class="pb-6 px-6">
        <v-row>
          <service-contract :typeofPlan="classificationPlan"></service-contract>
        </v-row>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import ServiceContract from '@src/components/contracts/ServiceContract.vue'
import { paymentComputed, paymentMethods } from '@state/helpers'

export default {
  components: {
    ServiceContract,
  },
  props: {
    value: {
      require: true,
    },
  },
  computed: {
    ...paymentComputed,
    classificationPlan() {
      return (
        this.companyPaymentInfo?.currentSubscription?.plan?.classification ||
        'basic'
      )
    },
    valueLocal: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
  },
  methods: {
    ...paymentMethods,
  },
  async mounted() {
    this.loading = true
    await this.getPaymentData()
    this.loading = false
  },
}
</script>

<style scoped>
</style>
