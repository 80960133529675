<template>
  <v-card-text class="k-contract pt-4 px-5">
    <v-icon v-if="loading">fas fa-circle-notch fa-spin</v-icon>
    <div v-else v-html="currentContractHtml"></div>
  </v-card-text>
</template>
<script>
import axios from '@plugins/axios'

export default {
  name: 'agree-service-contract',
  props: {
    typeofPlan: {
      require: true,
      type: String,
      validator: (val) => ['free', 'basic', 'complete'].includes(val),
    },
  },
  data() {
    return {
      contractHtml: {
        free: null,
        basic: null,
        full: null,
      },
      html: null,
      loading: false,
    }
  },
  computed: {
    currentContractHtml() {
      return this.contractHtml[this.currentType]
    },
    currentType() {
      console.log(this.typeofPlan)
      switch (this.typeofPlan) {
        case 'free':
          return 'free'
        case 'basic':
          return 'basic'
        case 'complete':
          return 'full'
        default:
          return 'free'
      }
    },
  },
  methods: {
    async getContract() {
      let { free, basic, complete } = await axios
        .get('service-contract')
        .then((resp) => resp.data)
      this.contractHtml = {
        free,
        basic,
        full: complete,
      }
    },
  },
  async created() {
    this.loading = true
    this.getContract()
    this.loading = false
  },
}
</script>
